import { getEnvironment } from './utils/getEnvironment';

// @ts-expect-error extend window
window.initializeRemote = async (remoteKey: string) => {
	const { getRemote, getFederatedEnv } = await import(
		'@innovyze/lib_get_remote'
	);
	// @ts-expect-error wrong type
	const environment = getFederatedEnv(await getEnvironment());
	// @ts-expect-error unknown remoteKey type
	const remote = getRemote(remoteKey, environment);

	const [remoteReference] = Object.values(remote);
	const [key, url] = remoteReference.split('@');

	return new Promise(resolve => {
		const script = document.createElement('script');

		script.src = url;
		script.onload = () => {
			// @ts-expect-error unknown key type
			resolve(window[key]);
		};
		document.head.appendChild(script);
	});
};

// Initialize the app
import('./index');
